<template xmlns:div="http://www.w3.org/1999/html">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" data-vv-scope="frmTierIndex">
    <div class="row justify-content-md-center">
      <!-- FCL -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('form.tierLocal.tipoCargaList[3]') }}</h5>
            <div class="row">
              <!-- Modalidade Processo -->
              <div class="col-12 col-sm-2 form-group mb-4">
                <label>{{ this.$i18n.t('form.tierArmador.modalidadeProcesso') }}</label>
                <v-select name="formData.modalidadeProcesso" :disabled="true" label="formData.modalidadeProcesso" :clearable="false" :searchable="true" v-model="formData.modalidadeProcesso"  :options="modalidadeProcessoList"
                  v-validate="{ required: true }"
                >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
              </div>
              <!-- Tipo Operação -->
              <div class="col-12 col-sm-2 form-group mb-4">
                <label>{{ this.$i18n.t('form.tierArmador.tipoOperacao') }}</label>
                <v-select name="formData.tipoOperacao" :disabled="true" label="formData.tipoOperacao" :clearable="false" :searchable="true" v-model="formData.tipoOperacao"  :options="tipoOperacaoList"
                  v-validate="{ required: true }"
                >
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
              </div>
              <!-- Valor -->
              <custom-input
                v-model="formData.percentualAcrescimo"
                name="formData.percentualAcrescimo"
                :label="this.$i18n.t('form.cupom.valor')"
                type="text"
                rootClassName="col-sm-4 form-group mb-4"
                inputClassName="md-form-control"
                v-validate="{ required: true }"
                :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                :error="submitted && errors.first('formData.percentualAcrescimo') ? errors.first('formData.percentualAcrescimo') : ''">
              </custom-input>
            </div>
          </div>
         </div>
      </div>
    </div>
      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomInput from '@/components/Forms/CustomInput.vue'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import TierService from '@/services/TierService'

export default {
  name: 'TierPadraoForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tier_padrao') + ' - %s'
    }
  },
  data () {
    return {
      // List of options to select on select input
      tipoOperacaoList: [
        {
          id: 1,
          nome: 'Exportação'
        },
        {
          id: 2,
          nome: 'Importação'
        },
        {
          id: 3,
          nome: 'Nacional'
        }
      ],
      modalidadeProcessoList: [
        {
          id: 1,
          nome: 'Aéreo'
        },
        {
          id: 2,
          nome: 'Marítimo'
        },
        {
          id: 3,
          nome: 'Rodoviário'
        }
      ],
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input

      tierLocalList: [],
      emptyLocalTierOption: {
        id: null,
        codigo: 'Não exibir'
      },
      //  Selected Values (Form)
      formData: {
        tipoOperacao: null,
        codigo: null,
        percentualAcrescimo: null
      },
      submitted: false

    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput
  },
  // Apply filters to local filter
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data
    let promises = []

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      promises.push(TierService.getTierIndex(id))
    }

    Promise.all(promises).then((values) => {
      // Edit Info
      if (id) {
        let tierIndex = values[0].data
        _this.formData = {
          modalidadeProcesso: tierIndex.modalidadeProcesso,
          tipoOperacao: tierIndex.tipoOperacao,
          percentualAcrescimo: tierIndex.percentualAcrescimo
        }
      }
      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  computed: {
  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'IndexTierIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_tier: object.id } }).href
    },
    onSave (e) {
      var _this = this
      _this.submitted = true
      _this.isLoading = true
      _this.$validator.validateAll('frmTierIndex').then((valid) => {
        if (valid) {
          _this.isLoading = true

          let tierData = {
            modalidadeProcesso: _this.formData.modalidadeProcesso.id,
            tipoOperacao: _this.formData.tipoOperacao.id,
            percentualAcrescimo: _this.formData.percentualAcrescimo
          }

          // Sempre cria um novo para manter o histórico
          TierService.newTierIndex(tierData).then(response => {
            _this.$router.push({ name: 'IndexTierIndex' })
          }).catch(() => {
            _this.isLoading = false
          })
        }
      }).finally(() => {
        _this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }
</style>
